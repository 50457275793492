/* ellipses */
.ellipsis {
    font-size: 2rem;
    font-weight: bold;
    display: inline-block;
}

.ellipsis span {
    opacity: 0;
    animation: ellipsis 1.5s infinite;
}

.ellipsis span:nth-child(1) {
    animation-delay: 0.2s;
}

.ellipsis span:nth-child(2) {
    animation-delay: 0.4s;
}

.ellipsis span:nth-child(3) {
    animation-delay: 0.6s;
}

@keyframes ellipsis {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}